<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Checkliste erstellen / bearbeiten</h1>

        <v-row>
          <v-col cols="12" md="12">
            <h2>Checkliste: {{ checklist.title }}</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12">
            <h3 class="mt-3">Items</h3>
          </v-col>
        </v-row>

        <v-row class="item-row" v-for="item in checklist.items" v-bind:key="item._id">
          <v-col cols="12" md="10">
            <div class="item-panel">
              <h3>{{ item.title }}</h3>
              <v-row>
                <v-col>
                  <div v-html="item.body"></div>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn v-if="item.video_path" icon fab elevation="2" @click="showVideo(item.video_path)">
                    <v-icon>mdi-video</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>


          </v-col>
          <v-col cols="12" md="2">
            <v-btn class="w100 mb-4"
                   @click="editItem(item)"
            >Bearbeiten
            </v-btn>
            <v-btn class="w100"
                   @click="confirmDelete(item)">Löschen
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12">
            <h3 class="mt-3">Aktionen</h3>
          </v-col>

        </v-row>
        <v-row>
          <v-col>
            <v-btn
                class="mx-2"
                fab
                dark
                large
                color="success"
                @click="createItem">
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>

            <v-btn
                class="mx-2"
                fab
                dark
                large
                color="cyan"
                v-if="false"
            >
              <v-icon dark>
                mdi-content-save-all
              </v-icon>
            </v-btn>

            <v-btn
                class="mx-2"
                fab
                dark
                large
                color="cyan"
                @click="$router.push({name: 'Admin'})"
            >
              <v-icon dark>
                mdi-fire-truck
              </v-icon>
            </v-btn>

          </v-col>

        </v-row>
      </v-col>
    </v-row>

    <v-dialog
        v-model="editItemDialog"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Item bearbeiten</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-text-field
                    v-model="editItemTitle"
                    label="Titel"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <vue-editor v-model="content"
                            :editorOptions="editorSettings"
                            useCustomImageHandler
                            @image-added="handleImageAdded">
                </vue-editor>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <label class="d-block">Video ersetzen</label>
                <input
                    type="file"
                    accept="videos/*"
                    @change="attachVideo($event)"
                    ref="video"/>
              </v-col>
            </v-row>

          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="editItemDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveItem"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="createItemDialog"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Item erstellen</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-text-field
                    v-model="itemTitle"
                    label="Titel"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <vue-editor ref="myTextEditor"
                            v-model="content"
                            :editorOptions="editorSettings">

                </vue-editor>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <label class="d-block">Video hochladen</label>
                <input
                    type="file"
                    accept="videos/*"
                    @change="attachVideo($event)"
                    ref="video"/>


              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="createItemDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveNewItem"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="videoDialog"
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Video</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <video controls :src="videoSrc" class="w100"></video>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="videoDialog = false"
          >
            Schliessen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog.confirmDelete"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Item löschen?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=""
              text
              @click="dialog.confirmDelete = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="red"
              text
              @click="deleteItem"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snack.state"
        :timeout="snack.timeout"
        :color="snack.color"
        elevation="12"
        top
        right
    >
      {{ snack.message }}
    </v-snackbar>
  </v-container>

</template>

<script>
import axios from 'axios'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import {VueEditor, Quill} from "vue2-editor";

//import {quillEditor} from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-vue';

Quill.register("modules/imageResize", ImageResize);

export default {
  name: "ChecklistCreator.vue",
  props: ['id'],
  components: {
    VueEditor
  },
  data() {
    return {

      content: '<p>Item Text hier erfassen</p>',
      dialog: {
        confirmDelete: false
      },
      editorSettings: {
        modules: {
          imageResize: {}
        },
      },
      videoUpload: null,
      checklist: null,
      editItemDialog: false,
      createItemDialog: false,
      itemTitle: null,
      editItemTitle: null,
      editedItemId: null,
      videoDialog: false,
      itemToDelete: null,
      videoSrc: '',
      snack: {
        state: false,
        color: 'success',
        timeout: 2000,
        message: 'Checkliste wurde erfolgreich gespeichert!',
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      axios
          .get(process.env.VUE_APP_API_URL + '/checklists/' + this.id)
          .then((response) => {
            this.checklist = response.data
          })
    },
    loadChecklists() {
      // axios.get(process.env.VUE_APP_API_URL + '/checklists/by_module/' + this.module)
      //     .then((response) => {
      //       this.checklists = response.data
      //     })
    },
    createItem() {
      this.content = "<p>Hier den Item-Text eingeben</p>"
      this.createItemDialog = true
    },
    saveNewItem() {
      let formData = new FormData()
      formData.append('video', this.videoUpload)
      formData.append('body', this.content)
      formData.append('title', this.itemTitle)

      axios.post(process.env.VUE_APP_API_URL + '/checklists/' + this.id + '/items', formData)
          .then((res) => {
            console.log(res)
            if (res.status === 204) {
              this.snack.message = "Das Item wurde erstellt."
              this.snack.state = true
            }
          })
          .catch((error) => {
            console.log(error)
          }).finally(() => {
        this.createItemDialog = false
        this.getData()
        this.videoUpload = null
      })
    },
    saveItem() {
      let formData = new FormData()
      formData.append('video', this.videoUpload)
      formData.append('body', this.content)
      formData.append('title', this.editItemTitle)

      axios.patch(process.env.VUE_APP_API_URL + '/checklists/' + this.id + '/items/' + this.editedItemId, formData)
          .then((res) => {
            console.log(res)
            if (res.status === 204) {
              this.snack.message = "Das Item wurde angepasst."
              this.snack.state = true
            }
          })
          .catch((error) => {
            console.log(error)
          }).finally(() => {
        this.editItemDialog = false
        this.getData()
        this.videoUpload = null
      })
    },
    editItem(item) {
      this.editedItemId = item._id
      this.content = item.body
      this.editItemTitle = item.title
      this.editItemDialog = true
    },
    deleteItem() {
      this.dialog.confirmDelete = false
      axios.delete(process.env.VUE_APP_API_URL + '/checklists/' + this.id + '/items/' + this.itemToDelete._id)
          .then((res) => {
            console.log(res)
          })
          .finally(() => {
            this.getData()
          })
    },
    attachVideo(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.videoUpload = files[0];
    },
    showVideo(path) {
      this.videoSrc = process.env.VUE_APP_STATIC_URL + '/uploads/' + path
      this.videoDialog = true
    },
    beforeDestroy() {
      // Always destroy your editor instance when it's no longer needed
      this.editor.destroy()
    },
    confirmDelete(item) {
      this.itemToDelete = item
      this.dialog.confirmDelete = true
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      console.log('image handle')

      let formData = new FormData();
      formData.append("file", file);

      axios.post(
          process.env.VUE_APP_API_URL + '/checklists/upload', formData)
          .then(result => {
            console.log(result)
            let url = result.data.url; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", process.env.VUE_APP_STATIC_URL + '/uploads/' + url);
            resetUploader();
          })
          .catch(err => {
            console.log(err);
          });
    }
  }
}
</script>

<style>
.item-panel {
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  background-color: lightgrey;
  min-height: 120px;
}

.w100 {
  width: 100%;
}

.item-panel img {
  max-width: 100%;
  cursor: auto !important;
}

</style>